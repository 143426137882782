import logo from './Favicon.png';
import './App.css';
import Ztext from 'react-ztext'
import AnimatedCursor from "react-animated-cursor"

function App() {
  return (
    <div className="App">
      <AnimatedCursor
      innerSize={8}
      outerSize={16}
      color='0, 10, 5'
      outerAlpha={0.2}
      innerScale={0.8}
      outerScale={3}
      />
      <div className="App-navigation">
      <span>Lou Kishfy</span><span class="citySpan">Brooklyn, New York</span><span><a href="mailto:louiskishfy@gmail.com?subject=Hey Lou, I would love to check out some of your work!" class="hvr-buzz" target="_blank">Portfolio</a></span>
      </div>
      <Ztext
        depth="1px"
        direction="both"
        event="pointer"
        eventRotation="1deg"
        eventDirection="default"
        fade={false}
        layers={6}
        perspective="600px"
      >
      <header className="App-header">
      An interdisciplinary <br/> product designer.
      </header>
      </Ztext>

      <div className="App-subHeader">
      I’m a systems thinker that gets excited about solving big, complex problems and ambitious missions.
      <br/>
      <br/>
      I have a passion for using data to make decisions, and delivering simple, holistic, empathetic designs.
      <br/>
      <br/>
      I enjoy contributing to research where I can, help set success metrics, and run experimentation tests to discover what user problems we are solving for — validating what will drive the most value.
      </div>
      <div className="line">
      </div>
      <div className="App-currently">
      Currently helping folks manage pain at Hinge Health.
      </div>
      <div class="App-aboutThreeColumn">
      <span class="brlarge"></span>
      <div class="col1"><h2 class="subhead">Education</h2>
      <span class="br"></span>
      School of the Art Institue <br/>of Chicago <br/>
      M.Des Designed Objects '16
      <span class="br"></span>
      University of Rhode Island
      <br/>
      B.S. Ocean Engineering '12
      </div>
      <span class="brlarge"></span>
      <div class="col2"><h2 class="subhead">Core Skillset</h2>
      <span class="br"></span>
      UX/UI
      <span class="br"></span>
      Lean UX
      <span class="br"></span>
      Qualitative Research
      <span class="br"></span>
      Front-end Development
      <span class="br"></span>
      Product Experimentation
      <span class="br"></span>
      Product Strategy
      <span class="br"></span>
      Prototyping
      </div>
      <span class="brlarge"></span>
      <div class="col3"><h2 class="subhead">Contact Me</h2>
      <span class="br"></span>
      <a href="mailto:louiskishfy@gmail.com?subject=Hey Lou, reaching out to..." class="hvr-buzz" target="_blank">Email</a>
      <span class="br"></span>
      <a href="https://www.linkedin.com/in/lou-kishfy-29441843/" class="hvr-buzz" target="_blank">LinkedIn</a>
      <span class="br"></span>
      <a href="https://github.com/lkishfy" class="hvr-buzz" target="_blank">Github</a>
      <span class="br"></span>
      <a href="https://www.figma.com/@LouKishfy" class="hvr-buzz" target="_blank">Figma</a>
      </div>
      </div>
      <div class="row"><h2 class="subhead">Selected Experiences</h2></div>
      <div class="App-experiencesThreeColumn">
      <div class="col1">
      <div class="bufferColumn"></div>
      <span class="br"></span>
      <b>Hinge Health</b>
      <br/>
      Senior Product Designer, Growth
      <br/>
      July 2022 - Current
      <span class="br"></span>
      <span class ="justify">
      <div class="max">
      At Hinge Health, we have a mission to help all those who are dealing with pain through highly accessible care delivered by industry-leading programs that help folks at all stages of their lives.       <span class="br"></span>
      <span class="br"></span>
      I primarily focus on delivering product and design solutions for customer acquisition. 
      <span class="br"></span>
      Notable projects I've contributed to are the Hinge Health Learning Center; Engineering handoff guidelines for two different design systems; Website rebrand and redesign.
      <span class="br"></span>
      </div>
      </span>
      </div>
      <div class="col2">
      <span class="brMedium"></span>
      <b>Redesign Health</b>
      <br/>
      Experience Design Lead II
      <br/>
      January 2021 - June 2022
      <span class="br"></span>
      <span class ="justify">
      <div class="max">
      Taken Keen & Kins 0-to-1 in 9 months time, from opportunity-in-market to MMP — GTM strategy, user stories, happy maps, feature roadmaps and prioritization, user testing, technical architecture, defining KPIs/key metrics for Series A funding, service blueprints, no-code prototyping/experimentation, and end-to-end design: <a href="https://www.choosekeen.com/" class="hvr-buzz">https://www.choosekeen.com/</a>, <a href="https://www.kins.com" class="hvr-buzz">https://www.kins.com/</a>.
      <span class="br"></span>
      Implemented eng-friendly design system in Figma, including a library of common flows seen across operating companies (onboarding, payments, etc.).
      <span class="br"></span>
      Created cross-team product frameworks and processes for building our operating companies' MVPs.
      <span class="br"></span>
      </div>
      </span>
      </div>
      <div class="col3">
      <span class="brMedium"></span>
      <b>Sub Rosa</b>
      <br/>
      Senior Design Lead
      <br/>
      April 2019 - March 2020
      <span class="br"></span>
      <span class ="justify">
      <div class="max">
      Managed a cross-functional team of designers and developers for interactive technologies, websites, and mobile apps.
      <span class="br"></span>
      Created user flows, user stories, diagrams for user interactions, wireframes, mock-ups, hardware/software architecture documents.
      <span class="br"></span>
      Developed early-stage functional prototypes for features and experiences.
      </div>
      </span>
      </div>
      </div>
      <div class="App-experiencesThreeColumn">
      <div class="col1">
      <div class="bufferColumn2"></div>
      <b>Tellart</b>
      <br/>
      Creative Technologist
      <br/>
      January 2017 - April 2019
      <span class="br"></span>
      <span class ="justify">
      <div class="max">
      Defined launch strategy for multiple interactive storytelling products, led technology production in the Providence office.
      <span class="br"></span>
      Directly responsible for user testing, technical discovery, and implementation across multiple projects. Provided technical specifications and user documentation for ongoing maintenance.
      <span class="br"></span>
      Managed engineering partners and contributed to QA/UAT.
      <span class="br"></span>
      Experiments in AR, VR, Machine Learning, and Computer Vision.
      </div>
      </span>
      </div>
      <div class="col2">
      <div class="bufferColumn2"></div>
      <span class="special"></span>
      <b>Blackstone Pharmacy</b>
      <br/>
      Co-Founder
      <br/>
      June 2010 - May 2014
      <span class="br"></span>
      <span class ="justify">
      <div class="max">
      Developed and deployed a mobile pharmacy service which sent technicians to customers homes in-person or virtually to monitor compliance and work clinically. All customers that used the service stayed with the pharmacy.
      <span class="br"></span>
      Managed 340b program — tracked prescriptions filled, inventory, dispensing fees, cost of medication and other pertinent information.
      <span class="br"></span>
      Opened second pharmacy location — handled retail design, build, and setting up contracts with PBMs and insurance companies.
      <span class="br"></span>
      Success of clinical consulting and management programs on customer growth and retention led to a profitable acquisition by CVS.
      </div>
      </span>
      </div>
      <div class="col3"></div>
      </div>
      <div class="footer"></div>
    </div>
  );
}
export default App;
